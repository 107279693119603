<script setup lang="ts">

  // pinia stores
  import { storeToRefs } from 'pinia';
  import { useAuthStore } from "~/store/auth";
  const authStore = useAuthStore();
  const { authenticated, usergroups } = storeToRefs(authStore);

</script>

<template>
  <div
    class="fixed bottom-0 left-0 right-0 z-50 mb-14 opacity-50"
    v-if="authenticated === true && usergroups && usergroups.includes('developers')"
  >
    <div
      class="text-center text-txt-light font-sans bold text-sm flext items-center mx-auto w-3/4 py-2 bg-red-dark z-50 border-2 border-txt rounded-md shadow-md"
    >
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
      <span
        >Figyelem! Bejelentkezve {{ usergroups.includes('administrators') ? 'Adminisztrátorként' : 'Fejlesztőként' }}!
      </span>
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
      <!-- <HydrationStatus /> -->
    </div>
  </div>
</template>